import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 imageContainer">
            {" "}
            <img src="img/corporate2.jpg" className="img-responsive" alt=""  />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>
                We are a <strong>seed-stage startup</strong> on a mission to revolutionize data mining and stock trading. Our team consists of <strong>veteran engineers</strong> with years of experience in building scalable, cutting-edge technologies.
              </p>
              <p>
                We are a <strong>driven and passionate</strong> group, focused on bringing the power of machine learning and data analysis to the financial markets. Our ambition is matched by our dedication to quality, innovation, and customer success.
              </p>
              <p>
                We work <strong>remotely</strong> from across the <strong>continental US</strong>, collaborating seamlessly across time zones to deliver state-of-the-art solutions for our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
