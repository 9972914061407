import { Image } from "./image";
import React from "react";

export const WhyUs = (props) => {
  return (
    <div id="whyus">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Why Choose Us?</h2>
              <div className="list-style">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <ul>
                    <li><strong>Expert Team of Engineers:</strong> Our team consists of seasoned engineers with decades of combined experience in software, data mining, and machine learning.</li>
                    <li><strong>Cutting-Edge Technology:</strong> We use the latest in data mining, AI, and machine learning to provide innovative solutions to the financial markets.</li>
                    <li><strong>Real-Time Insights:</strong> Get instant, actionable insights with our advanced real-time inferencing capabilities, keeping you ahead of the market.</li>
                    <li><strong>Tailored Solutions:</strong> We offer personalized solutions to meet your unique needs, from API integrations to advanced analytics.</li>
                    <li><strong>Proven Track Record:</strong> Our veteran team has a history of delivering high-quality, scalable products across multiple industries.</li>
                    <li><strong>Remote Collaboration:</strong> We work seamlessly from across the continental US, offering flexible and efficient remote services.</li>
                    <li><strong>Customer-Centric Approach:</strong> Your success is our priority. We provide dedicated support and work closely with our clients to ensure their goals are met.</li>
                    <li><strong>Future-Ready Solutions:</strong> We build systems that scale with your business, ensuring you're always ready for the next big opportunity in the market.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/corporate1.jpg" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
